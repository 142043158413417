<template>
  <div
    class="credit-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
	  <div class="header">
		<img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
		<div class="base-info">
		  <van-image
			round
			width="20vw"
			height="20vw"
			:src="agentInfo.avatar ? agentInfo.avatar : defaultFace"
			fit="cover"
		  />
		  <div class="info">
			<span class="name">{{ agentInfo.nickName }}</span>
			<span class="item">ID: {{ agentInfo.incId }}</span>
			<span class="item" @click="onCopy(agentInfo.inviteCode)"
			  >邀請碼: {{ agentInfo.inviteCode }}</span
			>
			<span class="item" v-if="agentInfo.isTutor!=false">金牌導師</span>
		  </div>
		</div>
	  </div>
    <ul class="credit-menu">
	  <li>
		  <div style="margin:10px;padding:10px;">
			 <div>本轮任务结算截止时间</div> 
		     <div style="margin:10px 0px;">{{this.tutorStartSection}}至{{this.tutorEndSection}}</div>
		  </div>
		  <div style="margin:10px;padding:10px;">本轮导师补货达92,000/已完成{{this.ownMoney/100}}</div>
		  <div style="margin:10px;padding:10px;">首月导师及直系补货达92,000/已完成{{this. teamOneMoney/100}}</div>
		  <div style="margin:10px;padding:10px;">次月导师及直系补货达92,000/已完成{{this.teamTwoMoney/100}}</div>
		  
 		  <div style="margin:10px;padding:10px;">线下沙龙 {{offLine}} <span v-if="offLine=='未申请'|| offLine=='被駁回,請重新上傳' " style="color: red;margin-left:15px" @click="toTypeThree(agentInfo.inviteCode)" >前往申请</span></div>
		  <div style="margin:10px;padding:10px;">
			  线上课程 {{onLine}} 
			  <span v-if="onLine=='未申请'|| onLine=='请重新选择课程' " style="color: red;" @click="toTypeFour(agentInfo.inviteCode)" >選擇課程</span>
			  <span v-if="onLine=='请重新上传课件'|| onLine=='请上传课件' " style="color: red;" @click="toTypeFourTwo(agentInfo.inviteCode)" >上傳課件</span>
		  </div> 
	  </li>	
	  <li>
	    <a>
	      <span class="title" @click="toHistory()" >
	        <i class="iconfont icon-qianbao-04" style="color: #3cadff" />
	          歷史記錄
	      </span>
	    </a>
	  </li>

	 
    </ul>
  </div>

</template>

<script>
import { closeToast,showToast} from "vant";
import { getCookie,formatDateMonth} from "../util";

export default {
 
  computed:{
	   FiltergoodsMoney() {
	        return this.agentInfo.goodsMoney/100
	   },
	   FilterinMoney(){
		   return this.agentInfo.inMoney/100
	   },
	   FilteroutMoney(){
		   return this.agentInfo.outMoney/100
	   }
		 
  },
  data() {
    return {
      agentInfo: {},
	  isAddTeacher:false,
	  ownMoney:0,
	  teamOneMoney:0,
	  teamTwoMoney:0,
	  offLine:"未申请",
	  onLine:"未申请"
    };
  },

  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;

	
	this.axios
	  .get(this.actions.teacherSchedule)
	  .then((res) => {
			  if(res.data.code!=200){
				   showToast({
						message:res.data.message
				   })
			  }else{
				  this.tutorStartSection=formatDateMonth(res.data.data.tutorStartSection)
				  this.tutorEndSection=formatDateMonth(res.data.data.tutorEndSection)
				  this.teamOneMoney=res.data.data.teamOneMoney
				  this.teamTwoMoney=res.data.data.teamTwoMoney
				  this.ownMoney=res.data.data.ownMoney
				  if(res.data.data.salon.length==0){
					  this.offLine="未申请",
					  this.onLine="未申请"
				  }else{
					if(res.data.data.salon.state==0){
						this.offLine="已申请,待審核",
						this.onLine="未申请"
					}
					if(res.data.data.salon.state==1){
						this.offLine="已通過"
						
					}
					if(res.data.data.salon.state==2){
						this.offLine="被駁回,請重新上傳"
					}
					
				  }
				  if(res.data.data.curriculum.state==0){
				  	this.onLine="已申请,待審核"
				  }
				  //第一次被拒绝
				  if(res.data.data.curriculum.state==4){
				  	this.onLine="请重新选择课程"
				  }
				   //第二次被拒绝
				  if(res.data.data.curriculum.state==5){
				  	this.onLine="请重新上传课件"
				  }
				  //待上传ppt
				  if(res.data.data.curriculum.state==1){
				  	this.onLine="请上传课件"
				  }
				  //待二次审核
				  if(res.data.data.curriculum.state==2){
				  	this.onLine="请等待審核"
				  }
				  if(res.data.data.curriculum.state==3){
				  	this.onLine="審核通過"
				  }
				  
			  }
	  })
	  .catch((error) => {
		console.log("views.AgentGroup.init.error", error);
		closeToast();
		//this.init();
	  }); 
	
    this.axios
      .get(this.actions.memberInfo)
      .then((res) => {
        let { code, message, data } = res.data;
        console.log(code, message);
        if (code == 200) {
          this.agentInfo = data;
        } else {
          showToast({
            message:message,
            forbidClick: true,
			duration:2000,
            onClose: () => {
              this.$router.back()
            },
          });
        }
      })
      .catch((error) =>
        //请求失败，触发catch中的函数 可省略
        console.log(error)
      );
  },
  methods:{
	 toTypeThree(){
	 	  this.$router.push({
	 	    path: "/teacherAddTypeThree",
	 	  });
	 },
	 toTypeFour(){
	 	  this.$router.push({
	 	    path: "/teacherAddTypeFour",
	 	  });
	 },
	 toTypeFourTwo(){
		 this.$router.push({
		   path: "/teacherAddTypeFourTwo",
		 });
	 },
	 toHistory(){
		 this.$router.push({
		   path: "/teacherList",
		 });
	 }
  }
};
</script>


<style src="../assets/css/credit.css" scoped></style>



<style scoped>
@media (max-width: 678px) {
  .credit-page {
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 16px;
    background-color: #f7f7f9;
    --aog-credit-menu-bottom-margin: 10px;
    --van-cell-group-inset-padding: 0;
    --van-cell-right-icon-color: #666;
  }

  .credit-page .header {
    position: relative;
    line-height: 0;
    margin-bottom: 16px;
  }

  .credit-page .header .member-bg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .credit-page .header .base-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 4vw 15vw 4vw;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .credit-page .header .base-info .info {
    width: 0;
    padding-left: 3vw;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 26px;
  }

  .credit-page .header .base-info .info .name {
    font-size: 18px;
    color: #fff;
  }

  .credit-page .header .base-info .info .item {
    background-color: var(--van-card-price-color);
    color: #fff;
    display: inline-block;
    padding: 0 10px;
    line-height: 24px;
    font-size: 13px;
    margin: 3px 6px 3px 0;
    border-radius: 6px;
  }

  >>> .van-icon-aog {
    color: var(--van-card-price-color);
  }
}

@media (min-width: 679px) {
  .agroup-page {
    display: none;
  }

  .pc-my-page .main {
    padding: 36px 30px;
  }

  .pc-my-page .main .credit-info .item {
    cursor: pointer;
  }
}
</style>